
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useLocalstorageState } from 'rooks';
import { useApplications } from '@/api/application';
import { useUser } from '@/api/user';
import { getPageServerSideProps } from '@/core/page-helpers/get-page-props';
import { STORAGE_KEY_LOCAL_APP } from '@/services/local-storage/keys';
const Home = () => {
    const { user } = useUser();
    const { applications } = useApplications({ userId: user?.id });
    const [localAppId, setLocalAppId] = useLocalstorageState<number | 'undefined'>(STORAGE_KEY_LOCAL_APP);
    const router = useRouter();
    useEffect(() => {
        if (localAppId && localAppId !== 'undefined') {
            void router.push(`app/${localAppId}/dashboard`);
            return;
        }
        // TODO: What if you dont' have applications attached? Show something maybe
        if (applications.length > 0) {
            setLocalAppId(applications[0].id);
            void router.push(`app/${applications[0].id}/dashboard`);
        }
    }, [applications, localAppId, router, setLocalAppId]);
    return null;
};
const getServerSideProps = getPageServerSideProps({
    layout: 'noop',
});
export default Home;

    async function __Next_Translate__getServerSideProps__1938c7fa36c__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1938c7fa36c__ as getServerSideProps }
  